<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <div class="el-tabs-content">
          <div class="formBox">
            <el-form
              label-position="left"
              label-width="120px"
              :model="formLabelAlign"
            >
              <el-form-item label="课程名称">
                <span>{{ userInfo.course_name }}</span>
              </el-form-item>
              <el-form-item label="教学班级">
                <span>{{ userInfo.grade_name }}</span>
              </el-form-item>
              <!-- <el-form-item label="学年-学期">
                <span>{{ userInfo.begin_time }}——{{ userInfo.end_time }}</span>
              </el-form-item>
              <el-form-item label="机构相关">
                <span>{{ userInfo.company_name }}</span>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = this.$store.state.saveAllList;
  },
  watch: {
    "$store.state.saveAllList": {
      handler(val) {
        this.userInfo = this.$store.state.saveAllList;
      },
    },
  },
  methods: {
    handleClick(tab, event) {},
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__item {
  padding: 0px 20px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  font-weight: 500;
  color: #409eff;
}
::v-deep .el-form {
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #dadce0;
  background-color: #fff;
  box-shadow: inset 4px 0 0 0 #4285f4;
  padding-left: 4px;
  box-sizing: border-box;
}
::v-deep .el-form-item {
  margin-bottom: 0;
  padding: 12px 0px 12px 40px;
  border-bottom: 1px solid #dadce0;
  box-sizing: border-box;
}
::v-deep .el-form-item__content,
::v-deep .el-form-item__label {
  font-size: 20px;
}
</style>
